import React from 'react';
import { Router } from "@reach/router"
import MultiPrint from '../components/ElementList/CheckedOperations/MultiPrint';

const MultiPrintPage = () => {
  return (
    <Router>
      <MultiPrint path="/multiprint/*" />
    </Router>
  );
};

export default MultiPrintPage;
